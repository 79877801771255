$ = jQuery;

var $log = function(msg) {
        if (App.debug) {
            if (arguments.length > 1) {
                var to_console = [];
                for (i = 1; i < arguments.length; i++) {
                    to_console[i - 1] = arguments[i][0];
                }
                console.log(msg, to_console);
            }
            else {
                console.log(msg);
            }
        }
    },
    App = {
        debug: true,
        init: function() {

            this.scripts();

        },
        loaded: function() {},
        scripts: function() {

            if (App.is_touch_device()) {
                $("body").addClass("touch");
            }
            else {
                $("body").addClass("no-touch");
            }

            var $win = $(window),
                $body = $("body"),
                scrollPos = $win.scrollTop();

            $win.on('scroll', function() {

                if ($(this).scrollTop() >= 50) {
                    $('body').addClass('page-scrolled');
                }
                else {
                    $('body').removeClass('page-scrolled');
                }

                scrollPos = $win.scrollTop();

            });

            // To top
            $(".back-top").on("click", function() {
                $("html,body").animate({
                    scrollTop: 0
                }, 550);
            });

            // Content link
            $(".content-link").click(function(e) {
                if ($(this).attr('data-slug').length && (typeof $(this).data('slug') !== 'undefined')) {
                    e.preventDefault();
                    var id = $(this).attr('data-slug');
                    $('html,body').animate({
                        scrollTop: $('#' + id).offset().top - 50
                    }, 550);
                }
            });

        },

        sliders: function() {

            var calendar_slider = $('.events-calendar-slider-wrapper .swiper-container');

            if (calendar_slider.length) {

                var calendar_swiper_slider = new Swiper(calendar_slider, {
                    loop: false,
                    effect: 'slide',
                    centeredSlides: true,
                    pagination: '.swiper-pagination',
                    paginationClickable: true,
                    paginationHide: true,
                    paginationType: 'bullets',
                    slidesPerView: 3,
                    spaceBetween: 0,
                    speed: 700,
                    grabCursor: false,
                    //autoplay: 6000,
                    navigation: {
                        nextEl: '.events-calendar-slider-wrapper .swiper-button-next',
                        prevEl: '.events-calendar-slider-wrapper .swiper-button-prev',
                    },
                    breakpoints: {
                        // when window width is <= 480px
                        480: {
                            slidesPerView: 1
                        }
                    }
                });

            }

        },

        mobMenu: function(menuWrapper) {

            $this = menuWrapper;

            /* toggle main menu nav */
            $(".menu-btn", $this).on("click", function() {

                menuWrapper.toggleClass("opened");
                $("body").toggleClass("opened");

            });

            $(window).on("keyup", function(event) {

                if ($("body").hasClass("opened")) {
                    switch (event.keyCode) {
                        case 27: //esc
                            menuWrapper.removeClass("opened");
                            $("body").removeClass("opened");
                            break;
                    }
                }

            });

        },
        is_touch_device: function() {
            return (("ontouchstart" in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
        }

    };

/*$(document).on("ready", function() {

    App.init();

});*/


$(window).on("load", function() {

    App.init();
    App.mobMenu($(".page-header"));

});
